// detail-dataset.component.ts
import { Component, Vue, Prop } from "vue-facing-decorator";
import { DbDataset } from "@/models/dataset";
import DatasetService from "../../services/dataset.service";
import { Subscription } from "rxjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import VsInput from "@/components/vs-input/vs-input.vue";
import { Suggestion } from "@/models/dataset";
import { VUE_API } from "@/constants";
// import DataMetricsBadge from "data-metrics-badge/dist/data-metrics-badge.js";
// import DataMetricsBadge from "@/components/datacite/DataMetricsBadge.vue";
import Minimap from "@/components/minimap/Minimap.vue";
import "dayjs/locale/en"; // Needed to offer month names in the selected language
import "dayjs/locale/de"; // Needed to offer month names in the selected language

import Accordion from "@/components/Accordion/Accordion.vue";

// import * as L from 'leaflet';

// import { ref } from 'vue';


import {
    FwbAccordion,
    FwbAccordionContent,
    FwbAccordionHeader,
    FwbAccordionPanel,
    FwbButton, 
    FwbTooltip,
    FwbModal
} from 'flowbite-vue';


@Component({
    name: "DatasetDetailComponent",
    components: {
        VsInput,
        Minimap,
        FwbAccordion,
        FwbAccordionPanel,
        FwbAccordionContent,
        FwbAccordionHeader,
        FwbButton, // Necessary?
        FwbTooltip, // Necessary?
        FwbModal, // Necessary?
        Accordion
        // DataMetricsBadge, // Commented out but prepared for future use
    },
})
export default class DatasetDetailComponent extends Vue {
    @Prop()
    datasetId!: string; // datasetId is passed as a prop and is required.
    searchTerm: string | Suggestion = ""; // Search term used in the search functionality.
    private subscriptions: Array<Subscription> = []; // Subscriptions to RxJS observables to prevent memory leaks.
    public dataset = {} as DbDataset; // Holds dataset details.
    private error: string = ""; // Stores error messages, if any.
    public loaded = false; // Indicates whether the dataset is fully loaded.
    public openAccessLicences: Array<string> = ["CC-BY-4.0", "CC-BY-SA-4.0"]; // Available open-access licenses.
    public portal = VUE_API + "/api/file/download/"; // Portal URL for file downloads.

    public isShowModal = false;

    closeModal() {
        this.isShowModal = false;
    }
    showModal() {
        this.isShowModal = true;
    }


    // get isEmbargoActive(): boolean {
    //     const embargoDate = this.dataset.embargo_date ? dayjs(this.dataset.embargo_date) : null;
    //     const today = dayjs();

    //     // FOR TESTING: ----------------------------------------------
    //         // const embargoDate = dayjs(this.dataset.server_date_published); // TESTING server_date_published is later than Created_at
    //         // const today = dayjs(this.dataset.created_at); // TESTING Created_at is before server_date_published and server_date_modified
    //     // -----------------------------------------------------------
      
    //     // Return true if embargo date exists and is in the future
    //     return embargoDate ? embargoDate.isAfter(today) : false;
    // }


    // get embargoEndDate(): string {
        // return this.dataset.embargo_date
        //     ? dayjs(this.dataset.embargo_date).format("DD.MM.YYYY")
        //     : "an unknown date";

        // FOR TESTING: ------------------------------------------
    //         return dayjs(this.dataset.created_at).format("DD.MM.YYYY")
    // }
      

    // If needed for stats
    // public post = {
    //     views: 25,       // Number of views for the dataset
    //     downloads: 1262, // Number of downloads
    //     citations: 2424, // Number of citations
    // };

    /**
     * Lifecycle hook: Called when the component is created.
     * Extends dayjs with advanced format plugin and determines whether to fetch dataset by ID or by DOI.
     */
    created(): void {
        dayjs.extend(advancedFormat); // Adds advanced date formatting options to dayjs.
        if (!this.datasetId.includes(".")) {
            // Fetch dataset by publish_id (numeric ID)
            this.getDataset(Number(this.datasetId));
        } else {
            // Fetch dataset by DOI (alphanumeric ID)
            this.getDatasetByIdentifier(this.datasetId);
        }
    }

    /**
     * Lifecycle hook: Called before the component is unmounted.
     * Unsubscribes from all subscriptions to prevent memory leaks.
     */
    beforeUnmount(): void {
        for (const subs of this.subscriptions) {
            subs.unsubscribe();
        }
    }

    /**
     * Handles search functionality based on user input or suggestion selection.
     * Opens a new window or navigates internally based on the host's domain.
     * @param suggestion - The suggestion or search term entered by the user.
     */
    onSearch(suggestion: Suggestion | string): void {        
        const host = window.location.host;
        const parts = host.split(".");
        if (parts[0] === "doi") {
            // If in DOI subdomain, open external search in a new window
            let term;
            if (typeof suggestion === "string") {
                term = suggestion;
                window.open("https://tethys.at/search/" + term, "_self");
            } else if (suggestion instanceof Suggestion) {
                term = suggestion.value;
                const type = suggestion.type;
                window.open("https://tethys.at/search/" + term + "/" + type, "_self");
            }
        } else {
            // Otherwise, route internally to search page
            let term;
            if (typeof suggestion === "string") {
                term = suggestion;
                this.$router.push({ name: "Search", params: { display: term } });
            } else if (suggestion instanceof Suggestion) {
                term = suggestion.value;
                this.$router.push({ name: "Search", params: { display: term, type: suggestion.type } });
            }
        }
    }

    /**
     * Fetches the dataset details by ID from the service and updates the component state.
     * @param id - The dataset's numeric ID.
     */
    private getDataset(id: number): void {
        const newSub = DatasetService.getDataset(id).subscribe({
            next: (res: DbDataset) => {
                this.dataset = res; // Store dataset in component state.
                this.loaded = true; // Mark as loaded.
            },
            error: (error: string) => {
                this.error = error; // Capture any errors during fetch.
            },
        });

        this.subscriptions.push(newSub); // Add subscription to array to manage unsubscribing later.
    }

    /**
     * Fetches the dataset details by DOI from the service and updates the component state.
     * @param id - The dataset's DOI (Digital Object Identifier).
     */
    private getDatasetByIdentifier(id: string): void {
        const newSub = DatasetService.getDatasetByDoi(id).subscribe({
            next: (res: DbDataset) => {
                this.dataset = res; // Store dataset in component state.
                this.loaded = true; // Mark as loaded.
            },
            error: (error: string) => this.errorHandler(error),
        });

        this.subscriptions.push(newSub); // Add subscription to array.
    }

    /**
     * Handles errors and updates the error message in the component.
     * @param err - Error message.
     */
    private errorHandler(err: string): void {
        this.error = err; // Update error message.
    }

    /**
     * Navigates back by one page in the router history, similar to browser back.
     */
    public goBack(): void {
        this.$router.go(-1); // Go back one step in the browser history.
    }

    /**
     * Extracts the file extension from a given filename.
     * @param filename - The name of the file.
     * @returns The file extension as a string.
     */
    public getExtension(filename: string): string {
        return filename.substring(filename.lastIndexOf(".") + 1, filename.length) || filename;
    }

    /**
     * Formats the file size into a human-readable string with appropriate units.
     * @param file_size - The size of the file in bytes.
     * @returns The formatted file size string.
     */
    // public formatSize(file_size: number): string {
    //     let size = file_size;
    //     const unit = ["Byte", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]; // Units for size.
    //     let i;
    //     for (i = 0; size >= 1024 && i < unit.length - 1; i++) {
    //         size = size / 1024; // Convert size to appropriate unit.
    //     }
    //     return Math.round((size + Number.EPSILON) * 100) / 100 + " " + unit[i];
    // }

    /**
     * Formats the file size into a human-readable string with appropriate units.
     * @param file_size - The size of the file in bytes.
     * @param useSI - Whether to use SI (base-10) units. Default is false (binary units).
     * @returns The formatted file size string.
     */
    public formatSize(file_size: number, useBI: boolean = false): string {
        let size = file_size;
        const binaryUnits = ["Byte", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
        const siUnits = ["Byte", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const units = useBI ? binaryUnits : siUnits;
        const divisor = binaryUnits ? 1024 : 1000;

        let i;
        for (i = 0; size >= divisor && i < units.length - 1; i++) {
            size = size / divisor; // Convert size to appropriate unit.
        }

        return Math.round((size + Number.EPSILON) * 100) / 100 + " " + units[i];
    }


    /**
     * Formats a given date into a human-readable string with the full day, month, and year.
     * @param date - The date string to format.
     * @returns The formatted date string.
     */
    public getPublishedDate(date: string): string {
        return dayjs(date).format("ddd, MMMM Do, YYYY h:mm a");
    }

    /**
     * Formats a given date into a simpler "DD.MM.YYYY HH:mm" format.
     * @param date - The date string to format.
     * @returns The formatted date string.
     */
    public getHumanDate(date: string): string {
        return dayjs(date).format("DD.MM.YYYY");
        // return dayjs(date).format("DD.MM.YYYY HH:mm");
    }

    /**
     * Extracts the year from a given date string.
     * @param date - The date string to extract the year from.
     * @returns The year as a string.
     */
    public getYear(date: string): string {
        return dayjs(date).format("YYYY");
    }


    /**
     * Formats a given date into a human-readable string with the full day and year separated by a comma.
     * @param date - The date string to extract the year from.
     * @returns The year as a string.
     */
    public getSimpleDate(date: string): string {
        return dayjs(date).locale(this.$i18n.locale.toLowerCase()).format("MMMM D, YYYY");
    }

    /**
     * Returns the human-readable language string based on the language code.
     * @param language - The language code (e.g., "de" for German).
     * @returns The language name as a string.
     */
    public getLanguage(language: string): string {
        if (language === "de") {
            return "Deutsch";
        } else {
            return "English";
        }
    }

    /**
     * Generates a citation string for the dataset based on its authors and publication details.
     * @returns The citation as a string.
     */
    public getCitation(): string {
        let citation = this.dataset.authors
            .map((u) => {
                let name = u.last_name;
                if (u.first_name) {
                    name += ", " + u.first_name?.substring(0, 1).toUpperCase() + ".";
                }
                return name;
            })
            .join(", ");
        citation += " (" + dayjs(this.dataset.server_date_published).format("YYYY") + "): ";
        citation += this.dataset.MainTitle?.value;
        if (!this.dataset.MainTitle?.value.endsWith(".")) { // Check if the title already ends with a "." character to avoid duplicating it
            citation += ". " + this.dataset.creating_corporation + ", ";
        } else {
            citation += " " + this.dataset.creating_corporation + ", ";
        }
        citation += this.dataset.publisher_name;
        citation += ", Wien";
        return citation;
    }

    // Copy the citation text to the clipboard
    copyToClipboard() {
        const citationText = this.getCitation(); // Get the citation text
        navigator.clipboard
          .writeText(citationText)
          .then(() => {
            alert("Citation copied to clipboard!");
          })
          .catch((err) => {
            console.error("Could not copy text: ", err);
          });
    }


    /**
     * Generates a list of authors
     * @returns The list of authors (creators)
     */
    // public getAuthors(): string {
    //     let authors = this.dataset.authors
    //         .map((u) => {
    //             let name = u.last_name;
    //             if (u.first_name) {
                    // Split the first_name into words
                    // let words = u.first_name.trim().split(/\s+/);
                    // Keep the first word as is
                    // let firstWord = words[0];
                    // For subsequent words, take the first character, convert to uppercase, and add a period
                    // let initials = words.slice(1)
                    //     .map(word => word.charAt(0).toUpperCase() + '.')
                    //     .join(' ');
                    // Combine the last name with the formatted first name
    //                 name += ", " + firstWord;
    //                 if (initials) {
    //                     name += ' ' + initials;
    //                 }
    //             }
    //             return name;
    //         })
    //         .join("; ");
    
    //     return authors;
    // }
    

    /**
     * Generates a list of authors
     * @returns The list of authors (creators)
     */
    public getAuthors(): string {
        let authors = this.dataset.authors
            .map((u) => {
                let name = u.last_name;
                if (u.first_name) {
                    // Split the first_name into words
                    let words = u.first_name.trim().split(/\s+/);
                    
                    // Keep the first word as is
                    let firstWord = words[0];
                    // For subsequent words, take the first character, convert to uppercase, and add a period
                    let initials = words.slice(1)
                        .map(word => word.charAt(0).toUpperCase() + '.')
                        .join(' ');
                    // Combine the last name with the formatted first name
                    name += ", " + firstWord;
                    if (initials) {
                        name += ' ' + initials;
                    }
                }
                return name;
            })
            .join("; ");
    
        return authors;
    }
    

    /**
     * Computed property to extract authors for display and search.
     */
    get authorsList(): Array<{ lastName: string, displayName: string, identifier_orcid: string }> {
        return this.dataset.authors.map((author) => {
            let lastName = author.last_name;
            let displayName = lastName;
            let identifier_orcid = author.identifier_orcid;

            if (author.first_name) {
                // Format the display name as "Last Name, First Initial."
                const words = author.first_name.trim().split(/\s+/);
                const firstWord = words[0];
                const initials = words.slice(1)
                    .map((word) => word.charAt(0).toUpperCase() + '.')
                    .join(' ');
                displayName = `${lastName}, ${firstWord}${initials ? ' ' + initials : ''}`;
            }

            return { lastName, displayName, identifier_orcid };
        });
    }


    /**
     * Generates a formatted list of authors for use in the Vue template.
     * @returns The list of authors with display name and last name.
     */
    public getFormattedAuthors(): Array<{ lastName: string, displayName: string }> {
        return this.dataset.authors.map((u) => {
            let lastName = u.last_name;
            let displayName = lastName;

            if (u.first_name) {
                // Split the first_name into words
                let words = u.first_name.trim().split(/\s+/);
                // Keep the first word as is
                let firstWord = words[0];
                // For subsequent words, take the first character, convert to uppercase, and add a period
                let initials = words.slice(1)
                    .map(word => word.charAt(0).toUpperCase() + '.')
                    .join(' ');
                // Combine the last name with the formatted first name
                displayName += ", " + firstWord;
                if (initials) {
                    displayName += ' ' + initials;
                }
            }

            return {
                lastName: lastName,
                displayName: displayName
            };
        });
    }

    /**
     * A computed property to process CoverageAttributes for easier rendering in an Accordion component.
     */
    get coverageAttributes() {
        return this.dataset.CoverageAttributes.map((attribute: any) => {
            const key = Object.keys(attribute)[0];
            const values = attribute[key];
            return {
                key,
                values: {
                    "south-bound_latitute": values.y_min,
                    "west-bound_longitude": values.x_min,
                    "north-bound_latitute": values.y_max,
                    "east-bound_longitude": values.x_max,
                    minimum: values.min,
                    maximum: values.max,
                    absolute: values.absolut,
                    "time_minimum": values.tMin,
                    "time_maximum": values.tMax,
                    "time_absolute": values.tAbsolut
                },
            };
        });
    }

    accessNotFromDoi(): boolean {        
        const host = window.location.host;
        const parts = host.split(".");
        if (parts[0] === "doi") {
            console.log("From DOI");
            // If in DOI subdomain, open external search in a new window
            return false;
        } else {
            console.log("Not From DOI");
            return true;
        }
    }
    
    /**
     * Changes the current locale to the selected locale.
     * @param locale - The new locale to switch to (e.g., 'en', 'de').
     */
    public changeLocale(locale: string): void {
        this.$i18n.locale = locale;
    }
    
}